
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
    throw new Error('Missing Supabase environment variables');
} else {
    console.log('Supabase environment variables are set successfully');
}

const supabase = createClient(supabaseUrl, supabaseKey)

export default supabase