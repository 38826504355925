import supabase  from '../../config/supabaseClient';

import { useEffect, useState } from 'react';

const Bulletin = () => {
  const [fetchError, setFetchError] = useState(null);
  const [events, setEvents] = useState(null);
  useEffect(() => { 
    async function fetchRentals() {
      const {data, error} = await supabase
      .from('events')
      .select('*');


      if (error) {
        setFetchError('Could not fetch events');
        setEvents(null);
        console.log(error);
      }

      if (data) {
        setEvents(data);
        setFetchError(null);
        console.log('Supabase Events:',data );
      }


     
    }
    fetchRentals();
  }, []);

  return (
    <div className='Announcements-ViewPage'>

      <h1>Announcements</h1>

      {fetchError && <p>{fetchError}</p>}
      {events && (
        <ul>
          {events.map((event) => (
            <li key={event.id}>
              <h2>{event.title}</h2>
              <h3>{event.date}</h3>
              <p>{event.detail}</p>
              <a 
                className={`MainBTN ${event.buttonName === 'none' ? 'hidden' : ''}`} 
                href={`mailto:${event.contactEmail}?subject=Inquiry about ${event.title}&body=Please send us an email regarding ${event.title}`}
              >
                {event.buttonName}
              </a>
            </li>
          ))}
        </ul>
      )}

    </div>
  );
};

export default Bulletin;