import React from 'react'
import HeroBanner from '../components/LandingPage/HeroBanner'
import Schedule from '../components/LandingPage/Schedule'
import Nav from '../components/LandingPage/MainNav'
import LandingPageStyles from '../modules/LandingPageStyles.css'
import Announcements from '../components/LandingPage/Announcements'
import Sacraments from '../components/LandingPage/Sacraments'
import FeedBack from '../components/LandingPage/FeedBack'
// import Bulletin from '../components/LandingPage/Bulletin'
import SafeEnvironment from '../components/LandingPage/SafeEnvironment'
import PhotoGallery from '../components/LandingPage/PhotoGallery'
// import Calendar from '../components/Calendar'


function LandingPage(props) {
    <LandingPageStyles></LandingPageStyles>
  return (
    <div className='LandingPage-PageView'>
        <HeroBanner></HeroBanner>
        <Schedule></Schedule>
        <Nav></Nav>
        <Announcements></Announcements>

        {/* <Calendar></Calendar> */}
        {/* <Bulletin></Bulletin> */}
        <PhotoGallery></PhotoGallery>
        <Sacraments></Sacraments>
        <SafeEnvironment></SafeEnvironment>
        <FeedBack></FeedBack>
    </div>

  )
}


export default LandingPage
