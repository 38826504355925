import React, { useState, useEffect, useMemo } from 'react';

function PhotoGallery(props) {
  const [selectedImage, setSelectedImage] = useState("assets/images/ChurchEvents2024/1.jpg");
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = useMemo(() => [
    "assets/images/ChurchEvents2024/1.jpg",
    "assets/images/ChurchEvents2024/2.jpg",
    "assets/images/ChurchEvents2024/3.jpg",
    "assets/images/ChurchEvents2024/4.jpg",
    "assets/images/ChurchEvents2024/5.jpg"
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    setSelectedImage(images[currentIndex]);
  }, [currentIndex, images]);

  const handleImageClick = (src, index) => {
    setSelectedImage(src);
    setCurrentIndex(index);
  };



  return (
    <div className='BackgroundMainColor'>
      <div  className='PhotoGallery'>
      <h1>Event Gallery</h1>
      
      {selectedImage && (
        <div className='modal'>
          <img className='modal-content' src={selectedImage} alt="" style={{ width: '100%', height: '260px' }} />
        </div>
      )}
      <div >
        <picture>
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt=""
              onClick={() => handleImageClick(src, index)}
              style={{
                border: selectedImage === src ? '2px solid blue' : 'none',
                cursor: 'pointer'
              }}
            />
          ))}
        </picture>
      </div>
      </div>
    </div>
  );
}

PhotoGallery.propTypes = {};

export default PhotoGallery;
